<template>
	<div>
		<course-view
			:course="course"
		/>
	</div>
</template>

<script>

	import CourseView            from '@/components/courses/CourseView';
	import routeParams           from '@/mixins/routeParams';
	import api                   from '@/services/api';

	export default {
		metaInfo () {
			if (!this.getCourseTitle) {
				return false;
			}
			return {
				title: this.getCourseTitle
			};
		},

		layout: 'v2default',

		components: {
			CourseView
		},
		mixins: [routeParams],
		data: () => ({
			course: {}
		}),
		computed: {
			getCourseTitle () {
				if (!this.course) {
					return false;
				}
				return this.course.title;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: this.getCourseTitle
					}
				];
			}
		},
		created () {
			this.setCourse();
		},
		methods: {
			async setCourse () {
				const course = await api.teaching.getCourseById({
					courseId: this.getCourseId
				});
				if (!course) {
					this.$store.commit('ui/showError');
					return false;
				}

				const teacherProfile = await api.teacherProfiles.getTeacherProfileById({
					profileId: course.teacherProfileId
				});

        if (!teacherProfile) {
					this.$store.commit('ui/showError');
					return false;
				}

				course.teacherProfile = teacherProfile;

				this.course = course;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>
